import { Component } from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {AnalyticsService} from '../../../services/analytics.service';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent {
  constructor(private meta: Meta, private analytics: AnalyticsService) {
    this.meta.addTags([
      { name: 'description', content: 'Tejleskörű gépjárműjavítás Budapest frekventált helyén, Autójavítás XI.kerület. Javítás, karbantartás, kuplungcsere, fékjavítás, olajcsere.' },
      { name: 'keywords', content: 'autó javítás, gépjármű javítás, kuplungcsere, olajcserer, fék javítás, időszakos karbantartás, műszaki vizsga, eredetvizsga' },
      { name: 'robots', content: 'index,follow' },
      { name: 'og:type', content: 'website' },
      { name: 'og:title', content: 'index,follow' },
      { name: 'og:description', content: 'index,follow' },
      { name: 'og:image', content: 'index,follow' },
      { name: 'og:url', content: 'index,follow' },
      { name: 'og:site_name', content: 'index,follow' }
    ]);
  }
  phoneClick(): boolean {
    this.analytics.trackPhoneButton('Köszönő oldal telefon gomb');
    return true;
  }
}
