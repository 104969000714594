import { Injectable } from '@angular/core';
import { BaseRepository } from './base/base.repository';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class TestRepository extends BaseRepository {
  constructor(public httpClient: HttpClient) {
    super(httpClient);
    this.path = 'sendmail';
  }
  test = (payload) => {
    const path = this.path;
    return super.post(path, payload);
  }
}
