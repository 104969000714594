import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = req.clone(
      this.requestHeaderUpdate(req)
    );
    return next.handle(clonedRequest);
  }

  private requestHeaderUpdate = (req: HttpRequest<any>) => {
    console.log('interceptor');
    return {headers: req.headers.set('Accept-Language', 'hu-HU').set('Authorization', 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjAwMTEyMjEiLCJuYW1lIjoiVmVyZGFzemVyZWzFkSIsImlhdCI6MTUxNjIzOTAyMn0.IokQiatIv-6du8Zh-xSGMrTOAhMB9pQIgZJWaqyOVJ4')};
  }
}
