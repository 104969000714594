<!-- Start Inner Banner -->
<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Rólunk</h3>
            <ul>
                <li><a routerLink="/">Kezdőlap</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Rólunk</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start About Area -->
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img-2">
                    <img src="assets/img/about3.avif" alt="Verdaszerelő kft. gépjárműjavítás gyorsan és jó minőségben">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <span class="span-bg">Rólunk</span>
                        <h2>Miért gyere hozzánk?</h2>
                        <p>Mert autóddal kapcsolatos problémáidat gyorsan és szakszerűen megoldjuk, legyen szó összetett hibafeltárásról, vagy csak egy egyszerű olajcseréről.</p>
                    </div>
                    <ul>
                        <li><i class='bx bx-check-circle'></i> Olajcsere</li>
                        <li><i class='bx bx-check-circle'></i> Számítógépes diagnosztika, hibakód kiolvasás</li>
                        <li><i class='bx bx-check-circle'></i> Autó villamosság</li>
                        <li><i class='bx bx-check-circle'></i> Futómű javítás</li>
                        <li><i class='bx bx-check-circle'></i> Motorjavítás</li>
                        <li><i class='bx bx-check-circle'></i> Műszaki vizsgáztatás, eredetvizsga</li>
                        <li><i class='bx bx-check-circle'></i> Karosszéria javítás</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Start Appointment Area -->
<div class="appointment-area appointment-bg pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="appointment-form appointment-form-bg">
                    <div class="section-title">
                        <h2>Keress minket!</h2>
                        <p>Kérlek töltsd ki az alábbi űrlapot, hogy felvehessük veled a kapcsolatot. Amennyibe tudod írd le, hogy milyen problémát tapasztalsz autóddal kapcsolatban, vagy milyen karbantartási munkára lenne szükséged. Ne felejtsd el megadni az autód adatait is. Így kollégánk felkészülve tud megkeresni.</p>
                    </div>
                    <div class="newsletter-area">
                        <a class="default-btn" routerLink="/keress-minket">Ajánlatkérés</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="appointment-img">
                    <img src="assets/img/contact.jpg" title="Lépj kapcsolatba, autójavítás XI. kerület" alt="Lépj kapcsolatba, autójavítás XI. kerület">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Appointment Area End -->

