<!-- Start Inner Banner -->
<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Keress minket!</h3>
            <ul>
                <li><a routerLink="/">Főoldal</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Keress minket!</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Contact Area -->
<div class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="contact-form">
                    <div class="section-title">
                        <span class="span-bg">KAPCSOLAT</span>
                        <h2>Keress minket</h2>
                        <p>Amennyiben szükséged lenne segítségünkre, keress minket, és amint tudunk visszajelzünk a problémáddal kapcsolatban.</p>
                    </div>
                    
                    <form (ngSubmit)="onSubmit()" [formGroup]="contactForm" id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Neved" formControlName="name">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email címed" formControlName="email" required>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Telefonszámod: +36301234567" formControlName="phone_number" required>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Milyen ügyben keresel minket?" formControlName="msg_subject" required>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="8" placeholder="Írd ide az üzeneted, problémádat" formControlName="message" required></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" [disabled]="isSubmitting" class="default-btn">Küldés</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="contact-sidebar">
                    <h3 class="title">Kapcsolat</h3>
                    <div class="row">
                        <div class="col-lg-12 col-md-4">
                            <div class="contact-card">
                                <i class='bx bx-home-smile'></i>
                                <div class="content">
                                    <h3>Cím</h3>
                                    <p>1117 Budapest, Hunyadi János út 9..</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-4">
                            <div class="contact-card">
                                <i class='bx bx-phone-call'></i>
                                <div class="content">
                                    <h3>Telefonszám</h3>
                                    <p>  <a href="tel:+36-20-544-4409" (click)="!!phoneClick()">+36-20-544-4409</a></p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-4">
                            <div class="contact-card">
                                <i class='bx bxs-envelope'></i>
                                <div class="content">
                                    <h3>Email</h3>
                                    <p>   <a href="mailto:info@verdaszerelo.hu" >info@verdaszerelo.hu</a></p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->

<!-- Start Map Area -->
<div class="map-area">
    <div class="container-fluid m-0 p-0">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4793.951518545018!2d19.042766817010968!3d47.445368536268134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dd4b4b0d0d53%3A0xb7de20ec273eb40a!2sBudapest%2C%20Hunyadi%20J%C3%A1nos%20%C3%BAt%209%2C%201117!5e1!3m2!1sen!2shu!4v1743058261977!5m2!1sen!2shu" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</div>
<!-- Map Area End -->
