<!-- Start Slider Area -->
<div class="slider-area">
    <div class="home-slider owl-carousel owl-theme">
        <div class="slider-item bg-item1">
            <div class="container">
                <div class="slider-content">
                    <span>Gyorsak vagyunk és szakszerűek</span>
                    <h1>Gyors és szakszerű gépjármű javítás</h1>
                    <p>Vállaljuk rövid határidővel gépárműved szakszerű javítását. Egyeztess időpontot, és a lehető legrövidebb idő alatt elvégezzük a kért javítást</p>
                    <div class="slider-btn">
                        <a routerLink="/keress-minket" class="get-btn">Kérj időpontot!</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="slider-item bg-item2">
            <div class="container">
                <div class="slider-content">
                    <span>Időszakos szervíz</span>
                    <h1>Időszakos szervíz, olajcsere</h1>
                    <p>Csak egy egyszerű olajcserére van szükséged? Gyere és hamarosan mehetsz is tovább az autóddal, immár friss olajjal és szűrőkkel.</p>
                    <div class="slider-btn">
                        <a routerLink="/keress-minket" class="get-btn">Kérj időpontot!</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="slider-item bg-item3">
            <div class="container">
                <div class="slider-content">
                    <span>Autódiagnosztika</span>
                    <h1>Számítógépes diagnosztika</h1>
                    <p>Hibakódkiolvasáshoz és szakszerű hibafelderítéshez rendelkezésre állnak modern diagnosztikai eszközeink. Így a gépjárműved hibáját gyorsan és pontosan meg tudjuk határozni. </p>
                    <div class="slider-btn">
                        <a routerLink="/keress-minket" class="get-btn">Kérj időpontot!</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Slider Area End -->

<!-- Start Choose Area -->
<div class="choose-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Miért minket válassz?</span>
            <h2>Gyorsan és szakszerűen megjavítjuk autódat!</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="choose-card">
                    <div class="choose-icon one-bg">
                        <i class='bx bx-help-circle'></i>
                    </div>

                    <div class="content">
                        <h3>Hibafeltárás</h3>
                        <p>Minden javítási folyamatunk a hibafeltárással kezdődik. Amint megvan a hiba forrása jelentkezünk és egyeztetünk Veled.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="choose-card">
                    <div class="choose-icon two-bg">
                        <i class='bx bx-time'></i>
                    </div>

                    <div class="content">
                        <h3>Alkatrészek beszerzése</h3>
                        <p>Miután megbeszéltük a részleteket megkezdjük az alkatrészek beszerzését. A rendelt alkatrészek általában egy órán belül rendelkezésre állnak.</p>

                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                <div class="choose-card">
                    <div class="choose-icon three-bg">
                        <i class='bx bx-bulb'></i>
                    </div>

                    <div class="content">
                        <h3>Javítás</h3>
                        <p>A szükséges alkatrészeket beépítjük és már jöhetsz is az autódért. Munkánkra garanciát vállalunk.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->

<!-- Start About Area -->
<div class="about-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/img/about2.jpg" alt="Images">

                    <div class="about-img-shape">
                        <div class="shape-1"><img src="assets/img/about/shape1.png" alt="Images"></div>
                        <div class="shape-2"><img src="assets/img/about/shape2.png" alt="Images"></div>
                        <div class="shape-3"><img src="assets/img/about/shape2.png" alt="Images"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <span>Rólunk röviden</span>
                        <h2>Több mint 20 év szervíz tapasztalattal állunk rendelkezésdre márkafüggetlen javítóként</h2>
                        <p>Munkánk magas minőségét és ügyfeleink elégedettségét tartjuk a legfontosabbnak. Munkánk során az alábbiakat tűztük ki céljainknak:</p>
                    </div>

                    <ul>
                        <li><i class='bx bx-check-circle'></i> Megbízható külső partnerek</li>
                        <li><i class='bx bx-check-circle'></i> Modern szervíz felszerelések</li>
                        <li><i class='bx bx-check-circle'></i> Naprakész diagnosztikai felszerelések</li>
                        <li><i class='bx bx-check-circle'></i> Korrekt árak</li>
                        <li><i class='bx bx-check-circle'></i> Professzionális ügyfél kezelés</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Area End -->

<!-- Start Service Area -->
<div class="service-area pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Szolgáltatások</span>
            <h2>Mindig készen állunk, hogy a legjobb munkát végezzük neked.</h2>
            <p>Az alábbi szolgáltatásokat végezzük ügyfeleinknek, hogy a lehető legproblémamentesebb legyen a közlekedés.</p>
        </div>

        <div class="service-slider owl-carousel owl-theme pt-45">
            <div class="service-item">
                <a routerLink="/" class="service-icon">
                    <i class='flaticon-wheel'></i>
                </a>
                <h3><a routerLink="/service-details">Kerék centírozás</a></h3>
                <p>A gumiabroncs centrírozása más szóval a kerekek kiegyensúlyozását jelenti. A megfelelő állapot, ha a kerekek súlypontja (centruma) középre kerül és simán, rázkódás nélkül forog. A centrírozás során bevonatos cink és vas keréksúlyok elhelyezésével minimálisra csökkentjük a kerekek forgás közbeni rázkódását.</p>
            </div>

            <div class="service-item">
                <a routerLink="/" class="service-icon">
                    <i class='flaticon-settings'></i>
                </a>
                <h3><a routerLink="/service-details">Időszakos átvizsgálás</a></h3>
                <p>Ideális esetben a motorolaj, a levegőszűrő és pollenszűrő cseréjén túl, csak a gyártó által előírt alkatrészek cseréje történik meg. Minden esetben átvizsgáljuk a fékrendszert, a futőművet és ellenőrizzük a folyadékszinteket</p>
            </div>

            <div class="service-item">
                <a routerLink="/" class="service-icon">
                    <i class='flaticon-fan'></i>
                </a>
                <h3><a routerLink="/service-details">Klíma javítás</a></h3>
                <p>Nincs sarkvidéki hideg az autódban? Vállaljuk a klímarendszer ellenőrzését, szükség esetén tisztítását és töltését. Amennyiben a klímarendszer meghibásodott kedvező áron javítjuk és újra élvezheted a jeges időt.</p>
            </div>

            <div class="service-item">
                <a routerLink="/" class="service-icon">
                    <i class='flaticon-service'></i>
                </a>
                <h3><a routerLink="/service-details">Teljeskörű gépjármű javítás</a></h3>
                <p>Gépjárművedet teljeskörűen javítjuk márkafüggetlenül.Szükség esetén felkészítjük műszaki vizsgára, és le is vizsgáztatjuk. Nyitott szemmel dolgozunk, az esetlegesen feltárt további hibákról azonnal tájékoztatunk.</p>
            </div>

            <div class="service-item">
                <a routerLink="/" class="service-icon">
                    <i class='flaticon-service'></i>
                </a>
                <h3><a routerLink="/service-details">Futómű javítás</a></h3>
                <p>Telejskörű futómű ellenőrzés és javítással várunk műhelyünkben, akkor is ha csak meg akarsz győződmi, hogy minden rendben, illetve konkrét hibajelenség esetén is.</p>
            </div>

            <div class="service-item">
                <a routerLink="/" class="service-icon">
                    <i class='flaticon-service'></i>
                </a>
                <h3><a routerLink="/service-details">Kuplung javítás</a></h3>
                <p>A kuplung legfontosabb feladata, hogy a motor által előállított energiát megfelelő mértékben továbbítsa a sebességváltók felé. Azonban néha ennek a javítására is szükség lehet. Műhelyünkben szakszerűen elvégezzük a javítást és már gurulhatsz is tovább.</p>
            </div>
        </div>
    </div>
</div>
<!-- Service Area End -->


<!-- Start Achievements Area -->
<div class="achievements-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Eredményeink</span>
            <h2>Amire büszkék lehetünk</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-3 col-sm-6">
                <div class="achievements-card achievements-bg-1">
                    <i class="flaticon-rating"></i>
                    <h3>200+</h3>
                    <span>Elégedett ügyfél</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="achievements-card achievements-bg-2">
                    <i class="flaticon-service"></i>
                    <h3>2000+</h3>
                    <span>Megjavított autó</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="achievements-card achievements-bg-3">
                    <i class="flaticon-user"></i>
                    <h3>10+</h3>
                    <span>Külső partner</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="achievements-card achievements-bg-4">
                    <i class="flaticon-calendar"></i>
                    <h3>20+</h3>
                    <span>Év tapasztalat</span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Achievements Area End -->
<div class="map-area">
    <div class="container-fluid m-0 p-0">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1439.0559041006202!2d19.053495209124083!3d47.45523856726893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dd75a7e02ac3%3A0xed840cb2e1698b0d!2sBudapest%2C%20Budafoki%20%C3%BAt%2070%2C%201117!5e0!3m2!1sen!2shu!4v1697733193304!5m2!1sen!2shu" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</div>
<!--

&lt;!&ndash; Start Project Area &ndash;&gt;
<div class="project-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="project-title">
                    <div class="section-title">
                        <span>Our Projects</span>
                        <h2>Some Of Our Projects Work Where We Helped</h2>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="project-text">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et amet bibendum ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae pellentesque.</p>
                </div>
            </div>
        </div>

        <div class="tab project-tab">
            <ul class="tabs">
                <li><a href="#">Wheels</a></li>
                <li><a href="#">Steering</a></li>
                <li><a href="#">Brakes</a></li>
                <li><a href="#">Suspension</a></li>
                <li><a href="#">Tyre</a></li>
            </ul>

            <div class="tab_content current active pt-45">
                <div class="tabs_item current active">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img2.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Wheels</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img1.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Steering</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img3.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Tyre</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img4.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Steering</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img5.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Brakes</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img6.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Tyre</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img2.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Brakes</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img3.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Suspension</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img6.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Tyre</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img3.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Suspension</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img2.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Brakes</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img4.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Steering</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img4.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Steering</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img5.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Brakes</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                                <div class="project-card">
                                    <a routerLink="/projects-details">
                                        <img src="assets/img/project/project-img6.jpg" alt="Images">
                                    </a>
                                    <div class="project-content">
                                        <h3><a routerLink="/projects-details">Tyre</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more">Read More</a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
&lt;!&ndash; Project Area End &ndash;&gt;
-->

<!-- Start Testimonials Area
<div class="testimonials-area testimonials-bg  pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Testimonials</span>
            <h2>Let's Know About Our Client Says</h2>
        </div>

        <div class="testimonials-slider pt-45 owl-carousel owl-theme">
            <div class="testimonials-item">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-6">
                        <div class="testimonials-content">
                            <h3>Devit M. kolin</h3>
                            <span>CEO & Founder</span>
                            <p>Awesome dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitationaco laboris nisi ut aliquip commodo consequat.</p>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-6">
                        <div class="testimonials-img">
                            <img src="assets/img/testimonials/testimonials-img.jpg" alt="Images">

                            <div class="quote-text">
                                <i class='flaticon-left-quote'></i>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonials-item">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-6">
                        <div class="testimonials-content">
                            <h3>Robart D.dondoe</h3>
                            <span>CEO Of Car Company</span>
                            <p>Awesome dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitationaco laboris nisi ut aliquip commodo consequat.</p>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-6">
                        <div class="testimonials-img">
                            <img src="assets/img/testimonials/testimonials-img2.jpg" alt="Images">

                            <div class="quote-text">
                                <i class='bx bxs-quote-left'></i>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 Testimonials Area End

 Start Team Area
<div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Team</span>
            <h2>We Have an Expert & Dedicated Team Member</h2>
        </div>

        <div class="team-slider pt-45 owl-carousel owl-theme">
            <div class="team-item">
                <div class="team-img">
                    <img src="assets/img/team/team-img6.jpg" alt="Images">

                    <div class="social-icon">
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                        </ul>
                    </div>
                </div>

                <div class="content">
                    <h3>John Doe</h3>
                    <span>Founder - CEO</span>
                </div>
            </div>
            
            <div class="team-item">
                <div class="team-img">
                    <img src="assets/img/team/team-img3.jpg" alt="Images">

                    <div class="social-icon">
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                        </ul>
                    </div>
                </div>

                <div class="content">
                    <h3>Jenifar Ambrina</h3>
                    <span>Co-Founder</span>
                </div>
            </div> 

            <div class="team-item">
                <div class="team-img">
                    <img src="assets/img/team/team-img5.jpg" alt="Images">

                    <div class="social-icon">
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                        </ul>
                    </div>
                </div>

                <div class="content">
                    <h3>Johan Smith</h3>
                    <span>Manager</span>
                </div>
            </div> 

            <div class="team-item">
                <div class="team-img">
                    <img src="assets/img/team/team-img2.jpg" alt="Images">

                    <div class="social-icon">
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                        </ul>
                    </div>
                </div>

                <div class="content">
                    <h3>Sendy Alina</h3>
                    <span>Team Leader</span>
                </div>
            </div> 
        </div>
    </div>
</div>
 Team Area End

 Start Blog Area
<div class="blog-area pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Blogs</span>
            <h2>Latest Blog Post</h2>
            <p>We have more than one year work blog and we have a fantastic blog on the global market. This is really helpful for the end user.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-img1.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <span>January 01, 2023</span>
                        <h3><a routerLink="/blog-details">Modern Technology Has Invested Globally</a></h3>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-img2.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <span>August 01, 2023</span>
                        <h3><a routerLink="/blog-details">International Car Trade Make a Deal for Us</a></h3>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="blog-card">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-img3.jpg" alt="Images">
                    </a>
                    <div class="content">
                        <span>September 09, 2023</span>
                        <h3><a routerLink="/blog-details">Marketing Policy Added on the Policy Service</a></h3>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 Blog Area End -->
