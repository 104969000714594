import {Component, OnInit} from '@angular/core';
import {AnalyticsService} from '../../../services/analytics.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    constructor(private analytics: AnalyticsService) {
    }
    ngOnInit(): void {}
    phoneClick(): boolean {
        this.analytics.trackPhoneButton('Header telefon gomb');
        return true;
    }
}
