<!-- Footer Area -->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="Images"></a>
                    </div>
                    <p>Elkötelezettek vagyunk, hogy minnél gyorsabban szakszerűen megjavítsuk autódat. Vedd fel velünk a kapcsolatot, amennyiben szeretnéd ha autód teljeskörüen gondozva legyen.</p>
                    <div class="newsletter-area">
                            <a class="default-btn" routerLink="/keress-minket">Ajánlatkérés</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="footer-widget">
                    <h3>Linkek</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/kik-vagyunk-mi">Rólunk</a></li>
                        <li><a routerLink="/szolgaltatasaink">Szolgáltatások</a></li>
                        <li><a routerLink="/keress-minket">Ajánlatkérés</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Egyéb fontos linkek</h3>

                    <ul class="footer-list">

                        <li><a routerLink="/privacy-policy">Adatkezelési tájékoztató</a></li>
                        <li><a routerLink="/aszf">ASZF</a></li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-2">
                    <div class="footer-widget">
                        <h3>Elérhetőségünk</h3>

                        <ul class="footer-list-two">
                            <li>
                                <i class='bx bx-time'></i>
                                Hétfő - Péntek: 8.00 - 17:00
                            </li>
                            <li>
                                <i class='bx bx-home-smile'></i>
                                <a href="#" target="_blank">1119 Budapest, Budafoki út 70.</a>
                            </li>
                            <li>
                                <i class='bx bx-phone'></i>
                                <a href="tel:+36-20-544-4409" (click)="!!phoneClick()">+36-20-544-4409</a>
                            </li>
                            <li>
                                <i class='bx bxs-envelope'></i>
                                <a href="mailto:info@verdaszerelo.hu">info@verdaszerelo.hu</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer Area End -->

<!-- Copyright Area -->
<div class="copy-right-area">
    <div class="container">
        <div class="copy-right-text text-center">
            <p>©verdaszerelő Kft. 2023 </p>
        </div>
    </div>
</div>
<!-- Copyright Area End -->
