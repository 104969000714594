<!-- Start Inner Banner -->
<div class="inner-banner inner-bg9">
    <div class="container">
        <div class="inner-title text-center">
            <h3> Projects Details </h3>
            <ul>
                <li>
                    <a routerLink="/">Home</a>
                </li>
                <li>
                    <i class='bx bxs-chevron-right'></i>
                </li>
                <li> Projects Details </li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Projects Details Area -->
<div class="project-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="project-details-slider owl-carousel owl-theme">
                    <div class="project-details-item">
                        <img src="assets/img/project/project-details1.jpg" alt="Images">
                    </div>

                    <div class="project-details-item">
                        <img src="assets/img/project/project-details2.jpg" alt="Images">
                    </div>

                    <div class="project-details-item">
                        <img src="assets/img/project/project-details3.jpg" alt="Images">
                    </div>
                </div>

                <div class="project-article">
                    <h2>How to Select a Small Business Coach</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur suscipit massa ante, in ultrices mi ultrices vel. Maecenas egestas ex quis tempor lacinia. Suspendisse ipsum tellus, mattis vel ligula vel, tincidunt vulputate lacus. Suspendisse consequat porta urna, non faucibus turpis fringilla non. Cras auctor nibh a ipsum ornare tincidunt. Fusce semper lacus sed molestie molestie.In pulvinar tellus a purus gravida, non pharetra felis dictum. Sed sed mollis ligula. Fusce semper id lorem et ornare. Morbi semper dui eu est tincidunt, aliquet dictum sem commodo. </p>
                    <p>Nam aliquet molestie libero ut auctor. Integer interdum lacus eu nibh tempor pharetra. Suspendisse ornare massa et nulla tincidunt consequat. Nunc nec est vitae leo elementum commodo. Donec ac arcu dignissim, finibus turpis condimentum, accumsan leo. Suspendisse sit amet placerat dui, id bibendum elit. Proin suscipit est vel magna luctus dapibus. Morbi necscelerisque ligula. Vivamus lectus nisl, interdum tempor faucibus at, tincidunt et metus. Pellentesque in rhoncus est. Maecenas suscipit sem mattis risus maximus convallis. Quisque feugiat, tortor vel dapibus porttitor</p>
                </div>

                <div class="project-article-list">
                    <h2>Project Overview</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur suscipit massa ante, in ultrices mi ultrices vel. Maecenas egestas ex quis tempor lacinia. Suspendisse ipsum tellus, mattis vel ligula vel, tincidunt vulputate lacus.</p>

                    <ul>
                        <li>
                            <i class='bx bx-check-square'></i>
                            We Provide Innovative Solutions With the Best
                        </li>
                        <li>
                            <i class='bx bx-check-square'></i>
                            We Provide the Best Customer Satisfaction
                        </li>
                        <li>
                            <i class='bx bx-check-square'></i>
                            We Deliver the Best Services
                        </li>
                        <li>
                            <i class='bx bx-check-square'></i>
                            Spark Plug Replacement
                        </li>
                    </ul>
                </div>

                <div class="project-article">
                    <h2>Project Result</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur suscipit massa ante, in ultrices mi ultrices vel. Maecenas egestas ex quis tempor lacinia. Suspendisse ipsum tellus, mattis vel ligula vel, tincidunt vulputate lacus. Suspendisse consequat porta urna, non faucibus turpis fringilla non. Cras auctor nibh a ipsum ornare tincidunt. Fusce semper lacus sed molestie molestie.In pulvinar tellus a purus gravida, non pharetra felis dictum. Sed sed mollis ligula. Fusce semper id lorem et ornare. Morbi semper dui eu est tincidunt, aliquet dictum sem commodo.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="project-side-bar">
                    <div class="project-categories">
                        <h3>Toyota Prius 2023</h3>

                        <ul>
                            <li>
                                <a href="#">Brand</a>
                                <span>Toyota</span>
                            </li>
                            <li>
                                <a href="#">Model</a>
                                <span>Prius</span>
                            </li>
                            <li>
                                <a href="#">Model Year</a>
                                <span>2023</span>
                            </li>
                            <li>
                                <a href="#">Client Name</a>
                                <span>Essien</span>
                            </li>
                            <li>
                                <a href="#">Category</a>
                                <span>Car Repair</span>
                            </li>
                            <li>
                                <a href="#">Book Date</a>
                                <span>12-09-2023</span>
                            </li>
                            <li>
                                <a href="#">Release Date</a>
                                <span>20-09-2023</span>
                            </li>
                            <li>
                                <a href="#">Status</a>
                                <span>Complete</span>
                            </li>
                        </ul>
                    </div>

                    <div class="side-bar-from">
                        <h3>SCHEDULE AN APPOINTMENT</h3>

                        <div class="appointment-form">
                            <form>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Phone">
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <select class="form-control">
                                                <option>Select your services</option>
                                                <option>Car repair</option>
                                                <option>Car washes</option>
                                                <option>Car style</option>
                                                <option>Car working</option>
                                            </select>	
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" placeholder="Your Message"></textarea>
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="subscribe-area">
                        <span>SUBSCRIBE</span>
                        <h2>SUBSCRIBE FOR NEWSLETTER</h2>
                        <form class="subscribe-form">
                            <input type="email" class="form-control" placeholder="Email*" name="EMAIL">
                            <button class="default-btn" type="submit">SUBSCRIBE  </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Projects Details Area End -->