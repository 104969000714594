import { Component, OnInit } from '@angular/core';
import {Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-services-details',
  templateUrl: './services-details.component.html',
  styleUrls: ['./services-details.component.scss']
})
export class ServicesDetailsComponent implements OnInit {

  constructor(private meta: Meta) {
    this.meta.addTags([
      { name: 'description', content: 'Tejleskörű gépjárműjavítás Budapest frekventált helyén, Autójavítás XI.kerület. Javítás, karbantartás, kuplungcsere, fékjavítás, olajcsere.' },
      { name: 'keywords', content: 'autó javítás, gépjármű javítás, kuplungcsere, olajcserer, fék javítás, időszakos karbantartás, műszaki vizsga, eredetvizsga' },
      { name: 'robots', content: 'index,follow' },
      { name: 'og:type', content: 'website' },
      { name: 'og:title', content: 'index,follow' },
      { name: 'og:description', content: 'index,follow' },
      { name: 'og:image', content: 'index,follow' },
      { name: 'og:url', content: 'index,follow' },
      { name: 'og:site_name', content: 'index,follow' }
    ]);
  }

  ngOnInit(): void {
  }

}
