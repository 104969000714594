import { Injectable } from '@angular/core';
declare var gtag;
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

constructor() { }

  public trackLeadCreated(){
     gtag('event', 'Új üzenet', {
        'event_category': 'BUTTON_CLICK',
        'event_label': 'Üzenet',
        'value': 'Új üzenet a verdaszerelőnek'   });
    }
    public trackLeadStarted(){
        gtag('event', 'Új üzenet írása oldal', {
            'event_category': 'BUTTON_CLICK',
            'event_label': 'Üzenet 1 lépés',
            'value': 'Új üzenet irás opció a verdaszerelőnek'   });
    }

    trackPhoneButton(eventType) {
        gtag('event', eventType, {
            'event_category': 'BUTTON_CLICK',
            'event_label': 'Hívás',
            'value': 'Felhasználó rákattintott egy telefonszámra'   });
    }
}
