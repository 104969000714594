<!-- Start Inner Banner -->
<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Szolgáltatásaink</h3>
            <ul>
                <li><a routerLink="/">Kezdőlap</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Szolgáltatásaink</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Services Details Area -->
<div class="service-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-article">
                    <div class="service-article-img">
                        <img src="assets/img/services.avif" alt="Szolgáltatásaink, verdaszerelő" title="gépjármű javítás, teljeskörű ügyintézés, kuplungcsere, fékjavítás, motor javítás, olajcsere">
                    </div>

                    <div class="service-article-list">
                        <h2>Időszakos karbantartás, olajcsere</h2>
                        <p>Teljeskörűen vállaljuk gépjárműved időszakos karbantartását. Miért fontos ez? Célunk, hogy ügyfeleink minnél hibamentesebben használhassák autójukat. Igyekszünk az alapos átvizsgálásink során feltárni a lehetséges hibákat és gyorsan megoldani azokat magasan felszerelt műhelyünkben. </p>
                        <p>Az időszakos olajcsere nem csak az olaj leengedéséből és az új betöltéséből áll. Ez egy remek alkalom a részletes átvizsgálásra és további megelőző karbantartások elvégzésére is.</p>
                        <p>Időszakos szervízcsomagunk az alábbiakból áll:</p>
                        <ul>
                            <li><i class='bx bx-check-circle'></i>Olajcsere</li>
                            <li><i class='bx bx-check-circle'></i>Levegő szűrő cseréje</li>
                            <li><i class='bx bx-check-circle'></i>Pollenszűrő cseréje</li>
                            <li><i class='bx bx-check-circle'></i> Amennyiben szükséges üzemanyagszűrő cseréje</li>
                            <li><i class='bx bx-check-circle'></i> Szíjak, motor ellenőrzése</li>
                            <li><i class='bx bx-check-circle'></i> Futómű, fékek ellenőrzése</li>
                            <li><i class='bx bx-check-circle'></i> Karosszéria ellenőrzése</li>
                        </ul>
                    </div>

                    <div class="service-article-content">
                        <h2>Futómű javítás</h2>
                        <p>A futómű, vagy más néven a felfüggesztés lényegében azért felel, hogy az autó biztonságos haladását biztosítsa. Egy meglehetősen összetett szerkezetről van szó, mely több alkatrészből tevődik össze.</p>
                        <p>A két komolyabb szerkezeti eleme a kerékfelfüggesztés és a lengés csillapító. Fő szerkezeti elemnek tekinthető a rugó is, ami leggyakrabban a lengéscsillapítóval egybeépítve található meg az autóban.</p>
                        <p>Fontos, hogy gépjárműved futóműve mindig jó állapotban legyen, hiszen részben ez felel a Te és az utasaid biztonságért. Rendszeres ellenőrzése és karbantartása fontos, épp ezért kiemelkedő figyelemmel vizsgáljuk át, szükség esetén javítjuk autód futóművét.</p>
                    </div>

                    <div class="service-article-content">
                        <h2>Fékjavítás</h2>
                        <p>A futómű, vagy más néven a felfüggesztés lényegében azért felel, hogy az autó biztonságos haladását biztosítsa. Egy meglehetősen összetett szerkezetről van szó, mely több alkatrészből tevődik össze.</p>
                        <p>A két komolyabb szerkezeti eleme a kerékfelfüggesztés és a lengés csillapító. Fő szerkezeti elemnek tekinthető a rugó is, ami leggyakrabban a lengéscsillapítóval egybeépítve található meg az autóban.</p>
                        <p>Fontos, hogy gépjárműved futóműve mindig jó állapotban legyen, hiszen részben ez felel a Te és az utasaid biztonságért. Rendszeres ellenőrzése és karbantartása fontos, épp ezért kiemelkedő figyelemmel vizsgáljuk át, szükség esetén javítjuk autód futóművét.</p>
                    </div>

                    <div class="service-article-list">
                        <h2>Egyéb szolgáltatásaink</h2>
                        <p>Egy autó a napi használatból eredően sajnos bármikor meghibásodhat. Ha fontos számodra, hogy gyorsan és szakszerűen javítsák az autódat, akkor az alábbi szolgáltatásokkal is állunk rendelkezésedre:</p>
                        <ul>
                            <li><i class='bx bx-check-circle'></i>Kuplung javítás</li>
                            <li><i class='bx bx-check-circle'></i>Motor javítás</li>
                            <li><i class='bx bx-check-circle'></i>Karosszéria javítás</li>
                            <li><i class='bx bx-check-circle'></i>Műszaki és eredvizsga</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="service-details-sidebar">
                    <div class="service-details-widget">
                        <h3 class="title">Szolgáltatásaink</h3>

                        <div class="service-details-categories">
                            <ul>
                                <li>Időszakos karbantartás</li>
                                <li>Motor javítás</li>
                                <li>Kuplung javítás</li>
                                <li>Futómű javítás</li>
                                <li>Olajcsere</li>
                                <li>Diagnosztika</li>
                                <li>Tejleskörű gépjárműjavítás</li>
                                <li>Műszaki vizsga</li>
                                <li>Eredetvizsga</li>

                            </ul>
                        </div>
                    </div>

                    <div class="service-details-widget">
                        <h3 class="title">Keress minket!</h3>

                        <div class="row pt-20">
                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bx-home-smile'></i>
                                    <div class="content">
                                        <h3>Cím</h3>
                                        <p>1117 Budapest, Hunyadi János út 9.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bx-phone-call'></i>
                                    <div class="content">
                                        <h3>Telefonszám</h3>
                                        <p>  <a href="tel:+36-20-544-4409">+36-20-544-4409</a></p>

                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-4">
                                <div class="service-widget-card">
                                    <i class='bx bxs-envelope'></i>
                                    <div class="content">
                                        <h3>Email</h3>
                                        <p>   <a href="mailto:info@verdaszerelo.hu">info@verdaszerelo.hu</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Services Details Area End -->
