import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  constructor(private toastr: ToastrService) {}

  showSuccess(message) {
    this.toastr.success(message, 'Siker', {
      enableHtml: true // Make sure to set enableHtml to true
    });
  }
  showWarning(message) {
    this.toastr.warning(message, 'Figyelmeztetés', {
      enableHtml: true // Make sure to set enableHtml to true
    });
  }
  showError(message: string) {
    this.toastr.error(message, 'Hiba történt', {
      enableHtml: true // Make sure to set enableHtml to true
    });
    }

}
