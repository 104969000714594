import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Utilities } from '../../utils/utilities';
import { PostPayloadInterface } from '../../models/post-payload.interface';
export abstract class SuperRepoRepository {
    protected httpClient: HttpClient;
    private serviceBaseUrl: string;
    protected constructor() {
        this.serviceBaseUrl = Utilities.getServiceUrl();
    }
    set serviceUrl(value: string) {
        this.serviceBaseUrl = value;
    }
    public get(path: string, options?: any): Observable<any> {
        console.log(this.serviceBaseUrl + path);
        return this.httpClient.get(this.serviceBaseUrl + path, options)
            .pipe(catchError(Utilities.handleError));
    }
    public post(path: string, body: PostPayloadInterface, options?: any): Observable<any>{
        return this.httpClient.post(this.serviceBaseUrl + path, body, options);
    }
}
