import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TestRepository} from '../../../repositories/backend-repository';
import {ToastrService} from 'ngx-toastr';
import {NotificationService} from '../../../services/notification.service';
import {Router} from '@angular/router';
import {AnalyticsService} from '../../../services/analytics.service';
import {Meta} from '@angular/platform-browser';
declare let $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  isSubmitting = false;
  constructor(private formBuilder: FormBuilder, private repo: TestRepository, private toastr: NotificationService, private router: Router, private analytics: AnalyticsService, private meta: Meta) {
    this.meta.addTags([
      { name: 'description', content: 'Tejleskörű gépjárműjavítás Budapest frekventált helyén, Autójavítás XI.kerület. Javítás, karbantartás, kuplungcsere, fékjavítás, olajcsere.' },
      { name: 'keywords', content: 'autó javítás, gépjármű javítás, kuplungcsere, olajcserer, fék javítás, időszakos karbantartás, műszaki vizsga, eredetvizsga' },
      { name: 'robots', content: 'index,follow' },
      { name: 'og:type', content: 'website' },
      { name: 'og:title', content: 'index,follow' },
      { name: 'og:description', content: 'index,follow' },
      { name: 'og:image', content: 'index,follow' },
      { name: 'og:url', content: 'index,follow' },
      { name: 'og:site_name', content: 'index,follow' }
    ]);
    this.analytics.trackLeadStarted();
  }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(4)]),
      email: new FormControl('', [
        Validators.required,
        Validators.email]),
      phone_number: new FormControl('', [
        Validators.required]),
      msg_subject:  new FormControl('', [
        Validators.required,
        Validators.minLength(4)]),
      message:  new FormControl('', [
        Validators.required,
        Validators.minLength(4)]),
    });
  }

  onSubmit() {
    $('.preloader').fadeIn('slow');
    const formData = this.contactForm.value;
    const payload = {
      id: 0,
      type: 'MAIL',
      attribute: formData
    };
    this.isSubmitting = true;
    this.repo.test(payload).toPromise().then((result) => {
      console.log(result.message);

      if (result.message === 'ok'){
        this.analytics.trackLeadCreated();
        this.toastr.showSuccess('Köszönjük üzeneted');
        this.router.navigate(['/koszonjuk']);
      }
      else{
        this.toastr.showWarning(result.message);
        $('.preloader').fadeOut('slow');
        this.isSubmitting = false;
      }
    })
        .catch((error) => {
          this.toastr.showError('Az üzeneted elküldése nem sikerült');
          $('.preloader').fadeOut('slow');
          this.isSubmitting = false;
          console.error(error); // Handle errors if the promise is rejected
        });
  }
  phoneClick(): boolean {
    this.analytics.trackPhoneButton('Contact telefon gomb');
    return true;
  }
}
