<!-- Start Inner Banner -->
<div class="inner-banner inner-bg3">
    <div class="container">
        <div class="inner-title text-center">
            <h3> Testimonials  </h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li> Testimonials </li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Start Testimonials Area -->
<div class="testimonials-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="span-bg">Testimonials</span>
            <h2>Let's Know About Our Client Says</h2>
        </div>

        <div class="testimonials-slider pt-45 owl-carousel owl-theme">
            <div class="testimonials-item">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-6">
                        <div class="testimonials-content">
                            <h3>Devit M. kolin</h3>
                            <span>CEO & Founder</span>
                            <p>Awesome dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitationaco laboris nisi ut aliquip commodo consequat.</p>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-6">
                        <div class="testimonials-img">
                            <img src="assets/img/testimonials/testimonials-img.jpg" alt="Images">
                            <div class="quote-text">
                                <i class='flaticon-left-quote'></i>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonials-item">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-6">
                        <div class="testimonials-content">
                            <h3>Robart D.dondoe</h3>
                            <span>CEO Of Qeni</span>
                            <p>Awesome dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitationaco laboris nisi ut aliquip commodo consequat.</p>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-6">
                        <div class="testimonials-img">
                            <img src="assets/img/testimonials/testimonials-img2.jpg" alt="Images">
                            <div class="quote-text">
                                <i class='bx bxs-quote-left'></i>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Testimonials Area End -->