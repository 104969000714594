<!-- Start Inner Banner -->
<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Köszönjük</h3>
            <ul>
                <li><a routerLink="/">Kezdőlap</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Köszönjük</li>
            </ul>
        </div>
    </div>
</div>
<!-- Start Appointment Area -->
<div class="appointment-area appointment-bg pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="appointment-form appointment-form-bg">
                    <div class="section-title">
                        <h2>Köszönjük, hogy felvetted velünk a kapcsolatot!</h2>
                        <p>Minden üzenetre igyekszünk a lehető leghamarabb válaszolni, kérünk várj türelemmel. Ha nagyon sürgös lenne, akkor bátran keress telefonos elérhetőségünkön:      <a href="tel:+36-20-544-4409" (click)="!!phoneClick()">+36-20-544-4409</a></p>
                        <p>Ha esetleg még valami üzeneted lenne számunkra, kattints az alábbi gombra és ne habozz megírni nekünk.</p>
                    </div>
                    <div class="newsletter-area">
                        <a class="default-btn" routerLink="/keress-minket">Ajánlatkérés</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="appointment-img">
                    <img src="assets/img/contact.jpg" title="Lépj kapcsolatba, autójavítás XI. kerület" alt="Lépj kapcsolatba, autójavítás XI. kerület">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Appointment Area End -->

