<!-- Start Top Header -->
<header class="top-header">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-9">
                <div class="header-left">
                    <div class="header-left-card">
                        <ul>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bx-home-smile'></i>
                                </div>
                                <a href="#" target="_blank">1117 Budapest, Hunyadi János út 9.</a>
                            </li>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bx-phone-call'></i>
                                </div>
                                <a href="tel:+36-20-544-4409" (click)="!!phoneClick()">+36-20-544-4409</a>
                            </li>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bxs-envelope'></i>
                                </div>
                                <a href="mailto:info@verdaszerelo.hu">info@verdaszerelo.hu</a>
                            </li>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bx-time'></i>
                                </div>
                                <a href="tel:+36-20-544-4409" (click)="!!phoneClick()">H-P: 8.00 - 17:00</a>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-3">
                <div class="header-right">
                    <div class="top-social-link">
                        <ul>
                          <!--  <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-google-plus'></i></a></li>-->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Top Header End -->

<!-- Start Navbar Area -->
<div class="navbar-area">

    <!-- Menu For Responsive -->
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/logo.png" alt="Logo">
        </a>
    </div>

    <!-- Menu For Desktop -->
    <div class="main-nav nav-bar">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo.png" alt="Logo">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">

                        <li class="nav-item"><a routerLink="/kik-vagyunk-mi" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Rólunk</a></li>

                        <li class="nav-item"><a routerLink="/szolgaltatasaink" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Szolgáltatások</a></li>
                     <!--   <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Szolgáltatásaink <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/services-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gépjármű szervizelés</a></li>

                                <li class="nav-item"><a routerLink="/services-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Műszaki vizsgáztatás</a></li>

                                <li class="nav-item"><a routerLink="/service-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Karosszéria javítás</a></li>
                            </ul>
                        </li>-->
                        <li class="nav-item"><a routerLink="/keress-minket" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Ajánlatkérés</a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>

</div>
<!-- End Navbar Area -->
