import { Injectable } from '@angular/core';
import { SuperRepoRepository } from './super-repo.repository';
import { HttpClient } from '@angular/common/http';
@Injectable({
    providedIn: 'root'
})
export class BaseRepository extends SuperRepoRepository {
    protected path: string = null;
    constructor(httpClient: HttpClient) {
        super();
        this.httpClient = httpClient;
    }
}
