<!-- Start 404 Error -->
<div class="error-area ptb-100">
    <div class="error-content">
        <h1>4 <span>0</span> 4</h1>
        <h3>Oops! Ez az oldal nem elérhető</h3>
        <p>Az oldal amit kerestél elköltözött vagy már nem elérhető.</p>
        <a routerLink="/" class="default-btn">Vissza a főoldalra</a>
    </div>
</div>
<!-- End 404 Error -->
